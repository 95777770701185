<template>
	<div class="inner">
		<div class="banner">
			<el-carousel autoplay :height="bannerHeight+'px'" :interval="5000" trigger="click" >
				<el-carousel-item v-for="(item,index) in bannerList" :key="index">
					<div class="inner-banner">
						<img ref="bannerHeight" :src="item.imgUrl" width="100%" />
					</div>
				</el-carousel-item>
			</el-carousel>
			<div class="news-banner">
				<div class="top-text blf ft36 pb40 lighter tlj">天津市鑫海达汽车贸易有限公司</div>
				<div class="news-banner-search over pt20 pb20">
					<el-select v-model="selectValue" filterable
							   remote
							   reserve-keyword placeholder="请输入搜索关键词/配置编号" :remote-method="getSearchKey" @change="handleDetails">
						<el-option v-for="item in searchOptions" :value="item.id" :label="item.name" :key="item.value">
						</el-option>
					</el-select>
					<i class="el-icon-search fr ft30"></i>
				</div>
			</div>
			<div class="banner-text">
				<el-row :gutter="10">
					<el-col :span="8">
						<div class="ft22 blf">平行进口汽车销售业务</div>
					</el-col>
					<el-col :span="8">
						<div class="ft22 blf tc">进口车辆自营自销无代理业务</div>
					</el-col>
					<el-col :span="8">
						<div class="ft22 blf tr">销售网络遍布全国各个城市</div>
					</el-col>
				</el-row>
			</div>
		</div>
		<div class="notice-bar over pl50 pr50">
			<div class="ml50 pt20 pb20 mr50 over ft18">
				<img src="../assets/notice-icon.png" class="fl mr30" width="28" />
				<el-carousel height="30px" direction="vertical" autoplay>
					<el-carousel-item v-for="(item,index) in newsList" :key="index">
						<div style="cursor: pointer;" @click="handleNotice(item.id)">公告：{{item.title}}</div>
					</el-carousel-item>
				</el-carousel>
			</div>
		</div>
		<div class="newcar-title pl50 pr50" style="height: 80px; line-height: 80px;">
			<div class="all-title over" style="height: 80px; line-height: 80px;">
				<div class="fl ft26 bold">新车速递</div>
				<div class="fl ft16 bl9 ml12 mt12"> / 优质车型抢先观看</div>
			</div>
		</div>
		<div class="newcar-banner" style="width: 1200px; margin:0 auto;">
			<!--<el-row>-->
				<!--<el-col :span="5">-->
					<!--<div class="car-list-left hover" @click="changeBannerIndex(index)"-->
						<!--:class="currentBannerIndex==index?'selected':''" v-for="(item,index) in newCarList" :key="index">-->
						<!--<img class="play-icon" src="../assets/play-icon.png" width="80" />-->
						<!--<el-image :src="item.mainPic"-->
							<!--style="display: block;width: 100%;height: 200px;" fit="cover"></el-image>-->
					<!--</div>-->
				<!--</el-col>-->
				<!--<el-col :span="19">-->
					<!--<el-carousel ref="carBanner" height="800px" @change="changeBanner" :autoplay="false">-->
						<!--<el-carousel-item v-for="(item,index) in newCarList" :key="index">-->
							<!--<div class="car-video-box">-->
								<!--<div @click="handleCarDetails(item.id)"-->
									<!--class="ft24 blf line1 tag line1 blf hover">{{item.name}}</div>-->
								<!--<video-->
									<!--:poster="item.mainPic"-->
									<!--:src="item.mainVideo"-->
									<!--width="100%" controls="controls" height="100%"></video>-->
							<!--</div>-->
						<!--</el-carousel-item>-->
					<!--</el-carousel>-->
				<!--</el-col>-->
			<!--</el-row>-->
			<el-row :gutter="30">
				<el-col :span="8" v-for="(item,index) in newCarList" :key="index" style="margin-top: 20px; margin-bottom: 20px;">
					<el-card :body-style="{ padding: '0px' }" style="min-height: 400px;">
						<video
                            ref="myVideo"
							:poster="item.mainPic"
							:src="item.mainVideo"
							width="100%" controls="controls" height="320px" @play="handlePlayVideo(index)"></video>
                <div style="padding: 14px;">
                    <span>{{item.name}}</span>
                </div>
            </el-card>
        </el-col>
    </el-row>
</div>
<div class="recommd-title blf pl50 pr50" style="height: 80px; line-height: 80px;">
    <!--<div class="tc ft44 bold">推荐车型</div>-->
    <!--<div class="tc ft24">汇集优质车型</div>-->
    <div class="fl ft26 bold">推荐车型</div>
    <div class="fl ft16 bl9 ml12 mt12"> / 汇集优质车型</div>
</div>
<div style="width: 1200px; margin:0 auto;">
	<el-row :gutter="30">
		<el-col :span="8" v-for="(item,index) in recommendList" :key="index" style="margin-top: 20px; margin-bottom: 20px;">
			<div class="recommd-car hover tr3" @click="handleCarDetails(item.id)">
				<el-image :src="item.mainPic"
						  style="width: 100%;height: 435px;display: block;" fit="cover"></el-image>
				<div class="pl20 pr20 ft20 blf pt20 pb20 line1 title">{{item.name}}</div>
			</div>
		</el-col>
	</el-row>
    <el-row>
        <el-col :span="24" style="text-align: right;margin-bottom: 30px;">
            <span @click="handleCar" style="cursor: pointer;">查看更多</span>
            <i class="el-icon-arrow-right"></i>
        </el-col>
    </el-row>
</div>
<!--<div class="look-more tc tr3 hover ft30" @click="handleCar">-->
    <!--<span class="blf">查看更多</span>-->
    <!--<i class="el-icon-arrow-right blf"></i>-->
<!--</div>-->
<div class="company-info pl50 pr50 blf over">
    <div class="all-title over ml50 mr50 pt40 pb40 pb16">
        <div class="fl blf ft36 bold">关于我们 </div>
        <div class="fl blf ft24 bl9 ml12 mt12"> / 我们的发展</div>
    </div>
    <div class="tc mt50 pt50 bold ft26 l16 pb26">{{companyInfo.name}}</div>
    <div class="tc ft22 l16 container l20 tj">
        {{companyInfo.companyDesc}}
    </div>
    <div class="line-btn tr3 hover" @click="$router.push('/about')">
        <span>查看更多</span>
        <i class="el-icon-arrow-right"></i>
    </div>
</div>
<div class="youshi-title pl50 pr50">
    <div class="all-title over ml50 mr50 pb16">
        <div class="fl ft36 bold">鑫海达优势</div>
        <div class="fl ft24 ml12 mt12"> / 为您提供优质服务</div>
    </div>
</div>
<div class="youshi-box">
    <el-row :gutter="50">
        <el-col :span="6">
            <div class="youshi-box-list hover tr3 tc pt50 pb50">
                <el-image fit="contain" style="height: 80px;" :src="require('@/assets/youshi1-list1.png')">
                </el-image>
                <div class="tc blf ft22 pt20">
                    平行进口汽车<br />
                    销售
                </div>
            </div>
        </el-col>
        <el-col :span="6">
            <div class="youshi-box-list hover tr3 tc pt50 pb50">
                <el-image fit="contain" style="height: 80px;" :src="require('@/assets/youshi1-list2.png')">
                </el-image>
                <div class="tc blf ft22 pt20">
                    自营自销无代<br />
                    理
                </div>
            </div>
        </el-col>
        <el-col :span="6">
            <div class="youshi-box-list hover tr3 tc pt50 pb50">
                <el-image fit="contain" style="height: 80px;" :src="require('@/assets/youshi1-list3.png')">
                </el-image>
                <div class="tc blf ft22 pt20">
                    丰田一级供应<br />
                    商
                </div>
            </div>
        </el-col>
        <el-col :span="6">
            <div class="youshi-box-list hover tr3 tc pt50 pb50">
                <el-image fit="contain" style="height: 80px;" :src="require('@/assets/youshi1-list4.png')">
                </el-image>
                <div class="tc blf ft22 pt20">
                    销售网络遍布<br />
                    全国
                </div>
            </div>
        </el-col>
    </el-row>
</div>
<el-row type="flex">
    <el-col :span="12">
        <div class="youshi-box-left tc pt30 pb30">
            <el-row :gutter="0">
                <el-col :span="12">
                    <el-image fit="contain" style="width: 70%;" :src="require('@/assets/index-phone.png')">
                    </el-image>
                </el-col>
                <el-col :span="12">
                    <div class="pt50 mt50 mr50 pr50">
                        <el-image fit="contain" style="width:50%" :src="require('@/assets/min-wechat-img.png')">
                        </el-image>
                        <div class="ft24 pt40 blf">扫码进入小程序</div>
                        <div class="ft16 blf">看视频，看直播</div>
                    </div>
                </el-col>
            </el-row>
        </div>
    </el-col>
    <el-col :span="12">
        <div class="youshi-box-right pt50 blf pb50">
            <div class="all-title over ml50 pl50 mr50 pb16">
                <div class="fl ft36 bold">新闻中心 </div>
                <div class="fl ft24 ml12 mt12"> / 时刻掌控最新资讯</div>
            </div>
            <div class="line-btn tr3 hover" @click="$router.push('/news')">
                <span>查看更多</span>
                <i class="el-icon-arrow-right"></i>
            </div>
        </div>
    </el-col>
</el-row>
</div>
</template>

<script>
import { pageList,pageCarList, bannerList, getAboutUs } from '@/api/index.js'
export default {
data() {
    return {
        // 查询参数
        queryParams: {
            title: '',
            category: '3',
            pageNum: 1,
            pageSize: 10
        },
        newsList: [],
        total: 0,
      videoElement:[],

        // 查询参数
        queryCarParams: {
            code: '',
            name: '',
            keyword:'', // 关键词
            category: '2',
            pageNum: 1,
            pageSize: 4
        },
        newCarList: [],
        recommendList: [],
        bannerList: [],
        companyInfo:{
            id: '',
            name: '',
            companyDesc: ''
        },

        selectOptions: [],
        searchOptions: [],
        selectValue: '',
        currentBannerIndex: 0,
        bannerHeight: '',
    }
},
mounted() {
    this.screenWidth = window.innerWidth;
    this.setSize();
    // 窗口大小发生改变时,调用一次
    window.onresize = () => {
        this.screenWidth = window.innerWidth;
        this.setSize();
    }
  this.videoElement = document.getElementsByTagName('video')

    this.getBranerList()
    this.getNoticeList()
    this.getCarList()
    this.getRecommendList()
    this.getSearchList()
    this.getAboutUsInfo()

},
methods: {
    /**  获取新闻信息列表 **/
    getNoticeList () {
        pageList(this.queryParams).then(res => {
            if (res.code === 200) {
                this.newsList = res.data.list;
                this.total = res.data.count;
            }
        })
    },
    /**  获取车型上新信息列表 **/
    getCarList () {
        this.queryCarParams.category = '2'
        this.queryCarParams.pageSize = 6
        pageCarList(this.queryCarParams).then(res => {
            if (res.code === 200) {
                this.newCarList = res.data.list;
            }
        })
    },
    /**  获取汽车推荐信息列表 **/
    getRecommendList(){
        this.queryCarParams.category = '3'
        this.queryCarParams.pageSize = 6
        pageCarList(this.queryCarParams).then(res => {
            if (res.code === 200) {
                this.recommendList = res.data.list;
            }
        })
    },
    /**
     * 获取所有轮播图信息
     */
    getBranerList(){
        bannerList().then(res => {
            if (res.code === 200) {
                this.bannerList = res.data;
            }
        })
    },
    /***  获取搜索列表 *****/
    getSearchList(){
        this.queryCarParams.category = ''
        this.queryCarParams.keyword = this.selectValue
        this.queryCarParams.pageSize = 1000
        pageCarList(this.queryCarParams).then(res => {
            if (res.code === 200) {
                this.selectOptions = res.data.list;
            }
        })
    },
    /*** 关于我们 **/
    getAboutUsInfo(){
        getAboutUs().then(res => {
            if(res.code === 200){
                this.companyInfo = {
                    id: res.data.id,
                    name: res.data.name,
                    companyDesc: res.data.companyDesc
                }
            }
        })
    },
    /***  跳转查看详情 ****/
    handleCarDetails(id){
        this.$router.push({
            path: '/carType-details',
            query:{
                id: id
            }
        })
    },
    /** 跳转车型列表***/
    handleCar(){
        this.$router.push({
            path: '/carTypeIntro',
            query:{
                category: '3'
            }
        })
    },

    /** 获取搜索信息  ***/
    getSearchKey(query){
        if (query !== '') {
            setTimeout(() => {
                this.searchOptions = this.selectOptions.filter(item => {
                    return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1 || item.code.toLowerCase().indexOf(query.toLowerCase()) > -1;
                });
            }, 200);
        } else {
            this.searchOptions = [];
        }
    },

    /** 跳转车型详情信息 **/
    handleDetails(){
        this.$router.push({
            path: '/carType-details',
            query:{
                id: this.selectValue
            }
        })
    },

    handleNotice(id){
        this.$router.push({
            path: '/news-details',
            query:{
                id: id
            }
        })
    },

  /**
   * 视频播放
   * @param index
   */
  handlePlayVideo(index){
    const videoElement = this.videoElement
    if (videoElement && videoElement.length > 0) {
      for (let i = 0; i < videoElement.length; i++) {
        if (i === index) {
          this.videoElement[i].play()
        } else {
          this.videoElement[i].pause()
        }
      }
    }
  },


    changeBanner(index) {
        this.currentBannerIndex = index
    },
    changeBannerIndex(index) {
        this.$refs.carBanner.setActiveItem(index)
    },
    setSize: function() {
        // 通过浏览器宽度(图片宽度)计算高度
        this.bannerHeight = 790 / 1920 * this.screenWidth;
    },
}

}
</script>
<style scoped>
.car-video-box {
position: relative;
height: 100%;
}

.car-video-box .tag {
background: rgba(0, 0, 0, .5);
position: absolute;
top: 0;
right: 0;
z-index: 2;
padding: 20px;
box-sizing: border-box;
max-width: 80%;
}

.youshi-box-right .line-btn {
margin: 26.5px 100px;
}

.youshi-box-right {
background-image: url(../assets/youshi-box-right-bg.png);
background-position: center;
background-size: cover;
padding: 27.05% 0 0;
height: 100%;
overflow: hidden;
box-sizing: border-box;
}

.youshi-box-left {
background-image: url(../assets/youshi-box-left-bg.png);
background-position: center;
background-size: cover;
}

.youshi-box-list {
background-color: rgba(255, 255, 255, .3);
border-radius: 4px;
height: 100%;
}

.youshi-box {
background-image: url(../assets/youshi1-bg.png);
background-position: center;
background-size: cover;
padding: 90px 50px;
}

.youshi-title {
background-image: url(../assets/youshi-title-bg.png);
background-position: center;
background-size: cover;
padding: 32px 50px;
}

.line-btn {
color: #FFFFFF;
font-size: 24px;
border-radius: 100px;
border: 1px solid #FFFFFF;
display: block;
width: 260px;
height: 50px;
line-height: 50px;
text-align: center;
margin: 100px auto 200px;
cursor: pointer;
}

.line-btn:hover {
background-color: #B4232F;
border: 1px solid #B4232F;
opacity: 1;
}

.company-info {
background-image: url(../assets/company-bg.png);
background-position: center;
background-size: cover;
}

.look-more {
background-color: #B4232F;
padding: 36px 0;
}

.banner .news-banner {
position: absolute;
top: 35%;
left: 50%;
z-index: 2;
transform: translate(-50%, -35%);
}

.banner .banner-text {
position: absolute;
bottom: 60px;
z-index: 2;
width: 100%;
box-sizing: border-box;
padding: 0 100px 0px;
}

.banner .news-banner-search {
background-color: rgba(255, 255, 255, 0.8);
width: 690px;
font-size: 18px;
position: relative;
}

.banner .news-banner-search .el-icon-search {
position: absolute;
right: 0;
top: 50%;
transform: translateY(-50%);
right: 20px;
}

.banner .news-banner-search .el-select {
width: 100%;
}

.banner .news-banner-search .el-select /deep/ .el-input__inner {
background-color: transparent;
font-size: 18px;
border: none;
font-weight: normal;
padding: 0 30px;
text-overflow: ellipsis;
overflow: hidden;
white-space: nowrap;
padding-right: 60px;
}

.banner .news-banner-search .el-select /deep/ .el-input__inner::-moz-placeholder {
color: #666;
}

.banner .news-banner-search .el-select /deep/ .el-input__inner::-ms-input-placeholder {
color: #666;
}

.banner .news-banner-search .el-select /deep/ .el-input__inner::-webkit-input-placeholder {
color: #666;
}

.banner .news-banner-search .el-select /deep/ .el-input__suffix {
display: none;
}

.el-select-dropdown .el-select-dropdown__item {
font-size: 16px;
padding: 10px 15px;
height: auto;
width: 100%;
}

.notice-bar {
background-color: #333333;
color: #FFF
}

.notice-bar /deep/ .el-carousel__indicators--vertical {
display: none;
}

.newcar-title {
background-image: url(../assets/newcar-title-bg.png);
background-position: center;
background-size: cover;
}

.car-list-left {
background-color: #000;
border-right: 20px solid #fff;
position: relative;
overflow: hidden;
}

.car-list-left .play-icon {
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
z-index: 2;
}

.car-list-left.selected {
border-right: 20px solid #B4232F;
}

.recommd-title {
/*padding: 100px 0;*/
background-image: url(../assets/recommd-title-bg.png);
background-position: center;
background-size: cover;
}

.recommd-car {
position: relative;
}

.recommd-car .title {
position: absolute;
bottom: 0;
z-index: 2;
width: 100%;
box-sizing: border-box;
text-align: center;
}
.newcar-banner /deep/ .el-carousel__indicators,.banner /deep/ .el-carousel__arrow{
display: none;
}
</style>
